import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;
const $host = axios.create({
    baseURL: baseUrl,
});

export const getHostels = async (currPage = 1, pageSize = 5) => {
    const { data } = await $host.get('rest/hostel', {
        params: {
            page: currPage,
            pageSize,
        },
    });
    return data.row;
};
export const getHostelsByKeyWord = async (keyword) => {
    const { data } = await $host.get('rest/hostel/search', {
        params: {
            keyword
        },
    });
    return data;
};