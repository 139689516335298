import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


function HeaderComponent() {
    const {t} = useTranslation();
    const {i18n} = useTranslation();

    const changeLanguage = (language) => {
        localStorage.setItem("lang", language);
        const localLang = localStorage.getItem("lang");
        i18n.changeLanguage(localLang);
    };

    let storedLanguage = localStorage.getItem("lang");
    useEffect(() => {
        if (!storedLanguage) {
            const defaultLanguage = "uz";
            localStorage.setItem("lang", defaultLanguage);
            i18n.changeLanguage(defaultLanguage);
        } else {
            i18n.changeLanguage(storedLanguage);
        }
    }, [i18n, storedLanguage]);

    const languages = [
        {code: "uz", label: "O'zbekcha"},
        {code: "uzk", label: "Ўзбекча"},
        {code: "ru", label: "Русский"}
    ];

    return (
        <nav className="navbar navbar-expand-lg text-bg-primary">
            <div className="container-fluid">
                <Link className="navbar-brand d-flex flex-row align-items-center text-bg-primary" to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                         className="bi bi-house-check mx-1" viewBox="0 0 16 16">
                        <path
                            d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.708L8 2.207l-5 5V13.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 2 13.5V8.207l-.646.647a.5.5 0 1 1-.708-.708z"/>
                        <path
                            d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.707l.547.547 1.17-1.951a.5.5 0 1 1 .858.514"/>
                    </svg>
                    HostelBaza
                </Link>
                <div className="btn-group w-80">
                    {languages.map((lang) => (
                        <Link
                            key={lang.code}
                            className={"btn btn-primary" + (storedLanguage === lang.code ? " active" : "")}
                            onClick={() => changeLanguage(lang.code)}
                            aria-current={storedLanguage === lang.code ? "page" : undefined}
                        >
                            {lang.label}
                        </Link>
                    ))}
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className="nav-link text-bg-primary" to="/">
                                {t("feedback")}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default HeaderComponent;
