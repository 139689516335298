import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import ru from "./langs/ru.json";
import uz from "./langs/uz.json";
import uzk from "./langs/uzk.json";

const lng = localStorage.getItem("lang") || "uz";

i18n.use(initReactI18next).init({
    resources: {
        ru,
        uz,
        uzk
    },
    lng: lng,
    fallbackLng: lng,
    interpolation: {
        escapeValue: false,
    },
})

export default i18n;
