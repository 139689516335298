import React, { useState, useEffect } from 'react';
import HomeComponent from "../../components/HomeComponent"; // Подключаем компонент, отображающий каждый хостел
import { useTranslation } from 'react-i18next';
import { getHostels, getHostelsByKeyWord } from "../../api";
import Spinner from "react-bootstrap/Spinner"; // Подключаем хук для перевода

function Home() {
    const [hostels, setHostels] = useState([]); // Инициализация состояния с пустым списком хостелов
    const [filteredHostels, setFilteredHostels] = useState([]); // Инициализация состояния filteredHostels с пустым списком хостелов
    const [page, setPage] = useState(1); // Инициализация состояния текущей страницы
    const [loading, setLoading] = useState(false); // Инициализация состояния загрузки
    const [hasMore, setHasMore] = useState(true); // Инициализация состояния, указывающего на наличие еще данных
    const { t } = useTranslation();

    // Функция для загрузки данных
    const fetchHostels = async () => {
        setLoading(true);
        // Здесь загружаем данные, например, с помощью API запроса
        try {
            const response = await getHostels(page);
            const newData = await response
            if (newData.length === 0) {
                setHasMore(false); // Если больше нет данных, устанавливаем hasMore в false
            } else {
                setHostels(prevHostels => [...prevHostels, ...newData]);
                setPage(prevPage => prevPage + 1);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching hostels:', error);
            setLoading(false);
        }
    };

    // Обработчик прокрутки
    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight &&
            !loading &&
            hasMore
        ) {
            fetchHostels(); // Вызываем функцию загрузки данных, если пользователь достиг конца страницы и есть еще данные для загрузки
        }
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchHostels(); // Вызываем функцию загрузки данных после таймаута
        }, 100); // Задержка в 100 мс для предотвращения конфликта со скроллом

        return () => clearTimeout(timeoutId);
    }, []);


    // Функция для обработки поиска по станции метро
    const handleSearchByMetro = async (searchValue) => {
        console.log(searchValue)
        if(searchValue !== "" || " ") {
            try {
                setLoading(true)
                const response = await getHostelsByKeyWord(searchValue)
                console.log(response)
                setFilteredHostels(response)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setFilteredHostels(hostels)
                setLoading(false)
            }
        } else {
            setFilteredHostels(hostels)
        }
    };

    // Обновляем отфильтрованные хостелы при изменении списка всех хостелов или строки поиска
    useEffect(() => {
        setFilteredHostels(hostels);
    }, [hostels]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loading, hasMore, handleScroll]); // Убедитесь, что обработчик скролла добавляется и удаляется при изменении loading и hasMore

    return (
        <div className="mt-3 mw-100 mx-auto row">
            <div style={{ width: '86%' }} className="col-lg-8 col-md-6 col-12 mx-auto">
                <input
                    type="text"
                    className="form-control"
                    placeholder={t("search") + "..."}
                    onChange={(e) => handleSearchByMetro(e.target.value)}
                />
            </div>
            {filteredHostels.length > 0 ? filteredHostels.map((item, index) => (
                <div className="col-lg-4 col-md-6 col-12 mt-2 mx-auto" key={index}>
                    <HomeComponent data={item} />
                </div>
            )) : ""}
            {loading && <div className="col-12 text-center mt-4"><p className={"d-flex align-items-center justify-content-center"}>
                <Spinner animation="grow" variant="primary" />{t("loading")}...</p></div>}
            {!loading && !hasMore && <div className="col-12 text-center mt-4"><p>Больше нет результатов</p></div>}
        </div>
    );
}

export default Home;