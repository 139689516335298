import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from "react-i18next";

function FooterComponent() {
    const {t} = useTranslation();

    return (
        <footer className="bg-dark text-light py-4">
            <Container>
                <Row>
                    <Col>
                        <p> {t('phone_number')} <a className={"link-light"} href="tel://+79261344707">+7 926
                            134-47-07</a>
                        </p>
                        <p>Whatsapp: <a className={"link-light"} href={"https://wa.me/+79261344707"}>+7 926
                            134-47-07</a></p>
                        <p>
                            {t("footer_text")}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <p>&copy; {new Date().getFullYear()} CodeCrafters.uz {t("rights_reserved")}</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default FooterComponent;
