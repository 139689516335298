import React, {Fragment} from "react";
import {Routes, Route} from "react-router-dom";
import {routes} from "../routes";
import HeaderComponent from "../components/HeaderComponent";
import FooterComponent from "../components/FooterComponent";
import Home from "../pages/Home";


const Routers = () => {
    return (<Fragment>
        <HeaderComponent/>
        <Routes>
            {/* Public routes */}
            {routes.map(({path, Component}) => (<Route key={path} path={path} element={<Component/>}/>))}

            {<Route path="*" element={<Home/>}/>}
        </Routes>
        <FooterComponent/>
    </Fragment>);
};

export default Routers;
